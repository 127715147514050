import React from "react";
import Layout from "../../layouts";
import Seo from "../../components/Seo";
import FlutterNativeImg from "../../images/blog/working-with-native-android-sdks-from-flutter/flutter-cover-img.png";
import IntroBanner from "../../components/IntroBanner";
import Img from "gatsby-image";
// import Isuru from "../../images/chrishmal-opt.png";
import Isuru from "../../images/blog/working-with-native-android-sdks-from-flutter/isuru-nanayakkara.png";
import { graphql } from "gatsby";

const SMSBot = (props) => {
  return (
    <Layout bodyClass="page-blog isuru">
      <Seo
        title="Working with Native Android SDKs from Flutter"
        description="One of our clients in the automotive industry came to us looking to build a mobile app. Their business spans across several areas such as transportation, warehousing and retail. They needed this app to help them organize and speed up all steps of their business processes."
        image={FlutterNativeImg}
      />
      <IntroBanner
        title="Working with Native Android SDKs from Flutter"
        by="by Isuru Nanayakkara"
        image={FlutterNativeImg}
      />
      <div className="content">
      <div className="container blog-post-container">
        <div className="row">
          <div className="col">
            <p>
              One of our clients in the automotive industry came to us looking to build a mobile app. Their business spans across several areas such as transportation, warehousing and retail. They needed this app to help them organize and speed up all steps of their business processes.
            </p>
            <p>
              Even though this app was to be made only for Android, we picked Flutter as the technology. Mainly due to the fact that Flutter allowed faster development and iteration cycles. While Flutter certainly helped us achieve speed and agility, we came across a challenge when we had to develop a set of key features.
            </p>
          </div>
        </div>
        <div className="row pt-2 pb-4">
          <div className="col">
            <h3>Challenge</h3>
            <p>
              As previously mentioned, our client's business has multiple facets. A single item  goes through many steps ranging from processing, packing, transporting to warehousing and selling. So they needed a way to keep track and verify these items throughout their processes. We decided QR codes were the ideal solution for this use case.
            </p>
            <p>
              A Bluetooth-enabled printer (Zebra ZD420d Direct Thermal Desktop Printer) and a handheld mobile device (CipherLab RS35 Mobile Touch Computer) were chosen as the devices. The mobile device could run Android applications. Plus it came with a powerful, in-built barcode scanner.
            </p>
            <p>
              The app we were building needed to communicate with these devices to print the labels and scan the QR codes. This was not as straightforward to implement as none of these device manufacturers offered dedicated Flutter SDKs.
            </p>
          </div>
        </div>
        <div className="row pb-4">
          <div className="col">
            <h3>Digging Deeper</h3>
            <p>
              The RS35 scanner device SDK was not available publicly so it did not have any third-party, open source Flutter plugins. While there were many wrapper plugins available for the Zebra SDK, none of them had the specific functionalities we needed. Some had not been updated in a long time while others simply did not work. So we set out to create our own solution to the problem. Fortunately Flutter came with help.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h3>Flutter Platform Channels </h3>
            <p>
              Flutter apps are written in Dart. For most non-trivial tasks, we can get by with Dart and the libraries provided by the Flutter framework. However, in cases where you need to work with platform-specific code (Kotlin/Java in Android, Swift/Objective-C in iOS), you need to dig a little deeper with the help of Platform Channels.
            </p>
            <p>
            <a
                href="https://docs.flutter.dev/development/platform-integration/platform-channels"
                target="_blank"
              >
                {" "}
                Platform Channels
              </a> is a mechanism in Flutter that enables you to communicate with platform-specific code. In other words, you can invoke code written in Swift/Objective-C and Kotlin/Java from within the Flutter project from Dart. And vice versa.
            </p>
            <p>
              There are several types of Platform Channels.
            </p>
            <ul>
              <li>Method Channel </li>
              <li>Event Channel </li>
              <li>Basic Message Channel </li>
            </ul>
            <p>
              We used Method Channels and Event Channels in our solution.
            </p>
          </div>
          <div className="col-md-7 pr-md-5 mb-sm-3">
            <Img
              fluid={props.data.WebComponents.childImageSharp.fluid}
              loading="eager"
              fadeIn={true}
              alt="Flutter platform channel"
            />
          </div>
        </div>
        <div className="row pb-0">
          <div className="col">
            <h3>Solution </h3>
            <p>
            First step was to add Zebra and RS35 SDKs to the Android project inside the Flutter app project. Then using Method and Event Channels, we were able to write a middleware layer to interact with the SDKs' functions from Flutter side.
              
            </p>
          </div>
        </div>
        <div className="row pb-0">
          <div className="col">
            <h5>Method Channels </h5>
            <p className=" mt-2">
              Method Channels are used to invoke a function on the native code side. This is done by passing the function name via the Method Channel. If you need to pass any additional data to the native side, it is possible to do so as well. These parameters are sent in a map (or dictionary as called in some languages). With Method Channel, you are not expecting a return value. This is just a fire-and-forget type of call.
            </p>
            <p>
              In our solution, we used Method Channels to invoke the functions to discover Bluetooth-enabled around the user. Then, again to initiate a print job. This time, the ZPL code of the label and the MAC address of the printer was sent as parameters.
            </p>
          </div>
        </div>
        <div className="row pb-3">
          <div className="col">
            <h5>Event Channels </h5>
            <p className=" mt-2">
              Event Channels, on the other hand, are used to listen to any events fired from the native code side. This is done by subscribing to a stream of events. It is possible to send data from the native code to the Flutter side as well.
            </p>
            <p>
              For example, in our solution, once the printer discovery function is triggered, it takes a while for the SDK to detect printers. And once they are detected, an Event Channel was used to send that data back to the Flutter side to show them in a list where the user can see them. An Event Channel was also used in scanning QR codes as well.
            </p>
            <p>
              Even though the data was passed back and forth via a messaging bridge, both printing and scanning functions were very fast. We were quite pleased with our solution as well as our client.
            </p>
            <p>
            iTelaSoft has extensive experience in Flutter app development for many industries from e-commerce, finance, and food curation. This post demonstrates what we learned on integrating platform-specific SDKs with Flutter.
            </p>
          </div>
        </div>
      </div>
      </div>
      <div className="container-fluid message pb-6 mb-5">
        <div className="row text-center">
          <div className="col-12 text-center">
            <div className="title-1 pb-3">
              We offer a complete range of IoT services and solutions
            </div>
          </div>
          <div className="col-12 mt-1">
            <a
              className="btn btn-primary"
              href="/services/iot-and-devices/"
              rel="noreferrer noopener"
              target="_blank"
            >
              Discover More
            </a>
          </div>
        </div>
      </div>
      <div className="author mt-5">
        <div className="container">
          <div className="row wrap-auther-detail">
            <div className="author-image">
              <img alt="Isuru" src={Isuru} />
            </div>
            <div className="d-flex align-items-center">
              <div className="ml-lg-3 ml-sm-0 detail">
                <h4>
                  <i>Isuru Nanayakkara</i>
                </h4>
                <span>
                  <i>Mobile Team</i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    WebComponents: file(relativePath: { eq: "blog/working-with-native-android-sdks-from-flutter/flutter-platform.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    UIControls: file(relativePath: { eq: "ui-controls.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default SMSBot;
